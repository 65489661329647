const moment = require('moment');

const getAdditionalFee = (arrayDays, arrayTimes) => {
    if (!arrayDays?.length && !arrayTimes?.length) {
        return 0;
    }

    const currentMoment = moment();
    const currentDate = currentMoment.format('YYYY-MM-DD');
    const currentTime = currentMoment.format('HH:mm:ss');

    const dateRate = arrayDays?.find(rate => rate.day === currentDate);
    if (dateRate) {
        return dateRate.additionalFee;
    }

    const timeRate = arrayTimes?.find(rate => {
        const startTime = moment(rate.initialTime, 'HH:mm:ss');
        const endTime = moment(rate.finalTime, 'HH:mm:ss');
        const checkTime = moment(currentTime, 'HH:mm:ss');

        return checkTime.isBetween(startTime, endTime, null, '[)');
    });

    return timeRate ? timeRate.additionalFee : 0;
};

export const FareCalculator = (distance, time, rateDetails, instructionData, decimal) => {

    const additionalFeeByDateOrTime = getAdditionalFee(rateDetails.days, rateDetails.times)

    let baseCalculated = (parseFloat(rateDetails.rate_per_unit_distance) * parseFloat(distance)) + (parseFloat(rateDetails.rate_per_hour) * (parseFloat(time) / 3600));
    if (rateDetails.base_fare > 0) {
        baseCalculated = baseCalculated + rateDetails.base_fare;
    }
    if (instructionData && instructionData.parcelTypeSelected) {
        baseCalculated = baseCalculated + instructionData.parcelTypeSelected.amount;
    }
    if (instructionData && instructionData.optionSelected) {
        baseCalculated = baseCalculated + instructionData.optionSelected.amount;
    }

	let total = baseCalculated > parseFloat(rateDetails.min_fare) ? baseCalculated : parseFloat(rateDetails.min_fare);

    let convenienceFee = 0;

	if (rateDetails.convenience_fee_type && rateDetails.convenience_fee_type == 'flat') {
        convenienceFee = rateDetails.convenience_fees;
    } else {
        convenienceFee = (total / (1 - parseFloat("." + rateDetails.convenience_fees))) - total;
    }

    let grand = total + convenienceFee;

    let additionalFeeGrandTotalByTime = 0;
    let additionalFeeTotalByTime = 0;

    if (additionalFeeByDateOrTime > 0) {
        additionalFeeGrandTotalByTime = (additionalFeeByDateOrTime / 100) * grand;
        additionalFeeTotalByTime = (additionalFeeByDateOrTime / 100) * total;
    }

    const grandTotalWithAdditionalFee = grand + additionalFeeGrandTotalByTime;
    const totalCostWithAdditionalFee = total + additionalFeeTotalByTime;

    // console.log("-----------------------------------------------------------")
    // console.log(rateDetails.times)
    // console.log("rateDetails.days: " + rateDetails.days)
    // console.log("baseCalculated: " + baseCalculated)
    // console.log("total: " + total)
    // console.log("convenienceFee: " + convenienceFee)
    // console.log("grand: " + grand)
    // console.log("additionalFeeTotalByTime: " + additionalFeeTotalByTime)
    // console.log("additionalFeeByDateOrTime: " + additionalFeeByDateOrTime)
    // console.log("additionalFeeGrandTotalByTime: " + additionalFeeGrandTotalByTime)
    // console.log("grandTotalWithAdditionalFee: " + grandTotalWithAdditionalFee)
    // console.log("totalCostWithAdditionalFee: " + totalCostWithAdditionalFee)
    // console.log("-----------------------------------------------------------")

    return {
        totalCost: parseFloat(totalCostWithAdditionalFee.toFixed(decimal)),
        grandTotal: parseFloat(grandTotalWithAdditionalFee.toFixed(decimal)),
        convenience_fees: parseFloat(convenienceFee.toFixed(decimal))
    };
}